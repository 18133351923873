
import { defineComponent } from "vue";
import { AppSource } from "@/data/global";
import { CONSTANTS } from "@/graphql/utils/utils";
import { RouteRecordName } from "vue-router";

export default defineComponent({
  name: "AccountLayout",
  setup() {
    function getParams(name: string, baseUrl: string) {
      let nextUrl = baseUrl;
      let icon = "pi pi-sign-in";
      if (name == "signIn") {
        nextUrl = baseUrl + "/sign-up";
        icon = "pi pi-user-plus";
      }
      return { icon, nextUrl };
    }
    function getBaseRoute(path: string, name: RouteRecordName) {
      name = String(name);
      if (path.includes(AppSource.desktop.account)) {
        localStorage.setItem(CONSTANTS.appSource, AppSource.desktop.account);
        return getParams(name, AppSource.desktop.account);
      } else if (path.includes(AppSource.android.account)) {
        localStorage.setItem(CONSTANTS.appSource, AppSource.android.account);
        return getParams(name, AppSource.android.account);
      } else if (path.includes(AppSource.ios.account)) {
        localStorage.setItem(CONSTANTS.appSource, AppSource.ios.account);
        return getParams(name, AppSource.ios.account);
      } else {
        localStorage.setItem(CONSTANTS.appSource, AppSource.web.account);
        return { icon: "pi pi-home", nextUrl: "/" };
      }
    }
    return {
      getBaseRoute,
    };
  },
});
